.intro-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 800px;
}
.shop-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  background: linear-gradient(to bottom right, #5f5f5f, #f9f9f9);
}
.welcome {
  margin: 0;
  margin-top: 150px;
  color: rgb(254, 254, 254);
}
.nameTile {
  margin: 0;
  margin-bottom: -15px;

  z-index: 3;
  color: rgb(254, 254, 254);
  font-family: "Times New Roman", Times, serif;
  font-size: 45px;
  font-weight: 900;
  text-shadow: 2px 2px black;
}
.shopImage {
  margin: 0;
  width: 60%;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 1px black;
}
.main-title-details {
  margin: 0;
  margin-top: -5px;
  margin-right: 5px;
  margin-left: 5px;
  color: rgb(254, 254, 254);
  font-weight: 900;
}

.subtitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}
.subtitle {
  color: rgb(254, 254, 254);
  text-shadow: 1px 1px black;
  margin: 0;
}

.openHours {
  color: rgb(237, 245, 153);
  text-shadow: 1px 1px black;
  margin: 0;
  margin-top: 30px;
}
.hours {
  color: rgb(254, 254, 254);
  text-shadow: 1px 1px black;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
}
.souvlakiImage {
  height: 800px;
  width: 55%;
  box-shadow: 0px 0px 10px 1px black;
}

@media (max-width: 1200px) {
  .intro-logo {
    height: auto;
  }
  .shop-details {
    width: 100%;
    padding-bottom: 50px;
  }
  .souvlakiImage {
    display: none;
  }
}

@media (max-width: 750px) {
  .shopImage {
    width: 80%;
  }
}

@media (max-width: 560px) {
  .tileDash {
    opacity: 0;
  }
  .title {
    flex-direction: column;
  }

  .subtitleDiv {
    flex-direction: column;
  }
}
