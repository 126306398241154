.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(to bottom right, #c9c5c5, #ffffff);
}
.ContactMainTitle {
  font-family: "Arial", sans-serif;
  font-size: 36px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  width: 90%;
  text-align: center;
}
.ContactDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contactMap {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shop_maps {
  width: 500px;
  height: 600px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.underline {
  width: 60%;
  border: 1px solid black;
  margin-top: 30px;
  margin-bottom: 10px;
}

.contactAddress {
  margin: 0;
  margin-bottom: -15px;
  color: rgb(0, 0, 0);
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 1px 1px white;
}

.contactAddressDetail {
  margin-bottom: -15px;
  margin-left: 10px;
  color: rgb(0, 0, 0);
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 1px 1px white;
}

.contactOpenHours {
  color: rgb(168, 183, 4);
  text-shadow: 1px 1px black;
  margin: 0;
  margin-top: 30px;
  font-size: 20px;
}

.contactHours {
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  color: rgb(0, 0, 0);
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 1px 1px white;
}

.contactTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 1px black;
}

.deliveryOpenDays {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.deliveryOpenDaysSubtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.contactMain-title-details {
  margin: 0;
  color: rgb(0, 0, 0);
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 1px 1px white;
  margin-right: 10px;
}

.contactSubtitle {
  margin: 0;
  margin-left: 10px;
}
.contactDetails {
  margin-bottom: -15px;
  margin-left: 10px;
  color: rgb(0, 0, 0);
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 1px 1px white;
}
.numberColor {
  color: blue;
}

.orderMethodsApps {
  display: flex;
  margin-top: 25px;
  margin-bottom: 20px;
  width: 60%;
  justify-content: space-evenly;
  align-items: center;
}

.orderMethodsUberEat {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  height: 50px;
  width: 50px;
  transition: all 0.3s;
}
.UberStyle {
  font-weight: 700;
  padding-left: 10px;
}
.orderMethodsJustEat {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  height: 50px;
  width: 50px;
  transition: all 0.3s;
}
.JustEatStyle {
  font-weight: 700;
  padding-left: 10px;
}
.orderMethodsPhone {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  width: 50px;
  transition: all 0.3s;
}

.OrderStyle {
  font-weight: 700;
  padding-left: 10px;
}

.orderMethodsUberEat {
  background-color: #00a844; /* Uber Eats green */
}

.orderMethodsJustEat {
  background-color: #ff7900; /* Just Eat orange */
}

.orderMethodsPhone {
  background-color: #2196f3;
}

.orderMethodsUberEat:hover {
  background-color: #00a843b8; /* Uber Eats green */
  font-size: 20px;
}
.orderMethodsJustEat:hover {
  background-color: #ff7700b1; /* Just Eat orange */
  font-size: 20px;
}
.orderMethodsPhone:hover {
  background-color: #2195f3b4;
  font-size: 20px;
}

@media (max-width: 1100px) {
  .shop_maps {
    width: 400px;
    height: 500px;
  }
}

@media (max-width: 950px) {
  .deliveryOpenDays {
    flex-direction: column;
    align-items: flex-start;
  }
  .deliveryOpenDaysSubtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .deliveryOpenDaysDash {
    opacity: 0;
  }
}

@media (max-width: 850px) {
  .ContactDiv {
    flex-direction: column;
  }

  .contactMap {
    width: 100%;
  }

  .shop_maps {
    width: 600px;
    height: 300px;
  }

  .contactForm {
    width: 100%;
    align-items: center;
  }
  .deliveryOpenDays {
    flex-direction: row;
    align-items: flex-start;
  }
  .deliveryOpenDaysSubtitle {
    flex-direction: row;
    align-items: flex-start;
  }
  .deliveryOpenDaysDash {
    opacity: 1;
  }
}

@media (max-width: 650px) {
  .shop_maps {
    width: 400px;
    height: 300px;
  }
}
@media (max-width: 525px) {
  .shop_maps {
    width: 400px;
    height: 250px;
  }

  .orderMethodsApps {
    width: 75%;
  }

  .orderMethodsUberEat,
  .orderMethodsJustEat,
  .orderMethodsPhone {
    font-size: 16px;
    height: 40px;
    width: 40px;
  }

  .orderMethodsUberEat:hover,
  .orderMethodsJustEat:hover,
  .orderMethodsPhone:hover {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .shop_maps {
    width: 370px;
    height: 250px;
  }

  .orderMethodsApps {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .deliveryOpenDays {
    flex-direction: column;
    align-items: flex-start;
  }
  .deliveryOpenDaysSubtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .deliveryOpenDaysDash {
    opacity: 0;
  }
}
