.aboutUs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 700px;
  overflow: hidden;
}

.leftDiv {
  height: 700px;
  width: 40%;
  margin-left: 5%;
  margin-right: 2px;
}
.aboutUsDetails {
  text-align: left;
  font-family: "Arial", sans-serif;
  padding: 20px;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}
.aboutUsGyros {
  width: 80%;
  height: 250px;
  margin-top: 20px;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rightSide {
  height: 700px;
  width: 50%;
  margin-left: 2%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUsSouvlakia {
  height: 550px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

@media (max-width: 1300px) {
  .aboutUsGyros {
    display: none;
  }
}

@media (max-width: 850px) {
  .rightSide {
    width: 40%;
  }
  .leftDiv {
    width: 50%;
  }
  .aboutUsSouvlakia {
    height: 450px;
  }
}

@media (max-width: 700px) {
  .aboutUs {
    flex-direction: column;
    height: 800px;
  }
  .leftDiv {
    width: 95%;
  }
  .rightSide {
    width: 100%;
    align-items: flex-start;
  }
  .aboutUsSouvlakia {
    height: 60%;
    width: 50%;
  }
}

@media (max-width: 500px) {
  .aboutUsSouvlakia {
    display: none;
  }
}
