.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin-top: 200px;
  margin-bottom: 200px;
}

.privacy-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2c3e50;
}

.privacy-date {
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 20px;
}

.privacy-heading {
  font-size: 20px;
  margin-top: 20px;
  color: #2980b9;
}

.privacy-text {
  font-size: 16px;
  line-height: 1.6;
  color: #34495e;
}
