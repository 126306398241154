.legal-section {
  padding: 20px;
  padding-top: 40px;
  background-color: #f1f1f1;
  text-align: center;
}

.legal-links {
  display: inline-block;
  margin-bottom: 20px;
}

.legal-link {
  margin: 0 10px;
  color: #2980b9;
  text-decoration: none;
  font-size: 14px;
}

.legal-link:hover {
  text-decoration: underline;
}

.copyright-section {
  font-size: 12px;
  color: #7f8c8d;
  margin-top: 20px;
  line-height: 1.5;
}

.copyright-section p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows text and logo to wrap on small screens */
}

.serve-savvy-link {
  color: #2980b9;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
}

.serve-savvy-link:hover {
  text-decoration: underline;
}

.serve-savvy-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* Responsive styling for screens smaller than 600px */
@media (max-width: 600px) {
  .legal-section {
    padding: 10px;
    margin-top: 20px;
  }

  .legal-link {
    font-size: 12px;
    margin: 0 5px;
  }

  .copyright-section {
    font-size: 10px;
  }

  .serve-savvy-logo {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
