.tripStyle {
  height: 800px;
  width: 100%;
  background-image: url("../../../images/paper-413882_1280.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.menuTitle {
  font-family: "Arial", sans-serif;
  font-size: 36px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  width: 90%;
  text-align: left;
}

.tagline {
  font-size: 24px;
  color: #555;
  font-style: italic;
}

.menuDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: auto;
}

.menuDetailsImages {
  position: relative;
  width: 50%;
  height: 650px;
}

.menuDetailsImagesSouvlakia {
  position: absolute;
  top: 0px;
  left: 50%;
  width: 280px;
  height: 380px;
}

.menuDetailsImagesGyros {
  position: absolute;
  top: 250px;
  width: 400px;
  height: 300px;
  left: 10%;
}

.menuRightSide {
  width: 50%;
}

.paragraph {
  margin-top: 0px;
  width: 70%;
  text-align: left;
  font-family: "Arial", sans-serif;
  padding: 20px;
}

.paragraphDetails {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
}

.menuButton {
  background-color: #3498db;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menuButton:hover {
  background-color: #84cbf7;
}

.menuDetailsImagesSalata {
  width: 60%;
  height: 270px;
  margin-top: 2px;
  border-top-left-radius: 2rem;
}

@media (max-width: 1200px) {
  .menuDetailsImagesSouvlakia {
    width: 230px;
    height: 330px;
  }
  .menuDetailsImagesGyros {
    width: 370px;
    height: 270px;
  }
  .menuDetailsImagesSalata {
    width: 300px;
    height: 220px;
  }
}

@media (max-width: 1000px) {
  .menuDetailsImagesSalata {
    display: none;
  }
}

@media (max-width: 900px) {
  .menuDetailsImagesSouvlakia {
    width: 190px;
    height: 300px;
  }
  .menuDetailsImagesGyros {
    width: 340px;
    height: 240px;
  }
}

@media (max-width: 800px) {
  .paragraph {
    width: 85%;
  }
}
@media (max-width: 750px) {
  .menuDetailsImagesSouvlakia {
    width: 60%;
    height: 300px;
    left: 11%;
  }
  .menuDetailsImagesGyros {
    width: 250px;
    height: 150px;
  }
  .paragraph {
    width: 85%;
  }
  .menuRightSide {
    width: 70%;
  }

  .tripStyle {
    height: 850px;
  }
}

@media (max-width: 670px) {
  .menuDetails {
    flex-direction: column-reverse;
  }
  .paragraph {
    margin-left: 10%;
    width: 100%;
  }
  .menuDetailsImagesSouvlakia {
    display: none;
  }
  .menuDetailsImages {
    width: 100%;
  }

  .menuDetailsImagesGyros {
    position: absolute;
    top: 0px;
    left: auto;
    width: 70%;
    height: 35%;
    right: 10%;
    border-top-left-radius: 2rem;
  }
  .menu {
    height: 1050px;
  }
  .tripStyle {
    height: 1050px;
  }
}
