/* General layout and styling */
.loginMainArticle {
  height: 100%;
  width: 100%;
  position: relative;
}

.loginArticle {
  height: 100vh;
  min-height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #a8edea 0%, #fed6e3 100%);
}

/* Form styling */
.loginFormIn {
  padding: 2rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.errorMsg {
  color: red;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
}

.loginFormIn h1 {
  margin-bottom: 1rem;
  color: #333;
}

.loginFormIn label {
  margin-top: 1rem;
  font-weight: bold;
  color: #333;
}

.loginFormIn input {
  width: 100%;
  padding: 10px;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.loginBtn {
  margin-top: 1rem;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.loginBtn:hover {
  background-color: #0056b3;
}

/* Additional options */
.loginRegisterDiv {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.SignInBtn,
.BackTiLogInBtn {
  margin-left: 5px;
  padding: 10px 20px;
  border: 1px solid #007bff;
  background-color: transparent;
  color: #007bff;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.SignInBtn:hover,
.BackTiLogInBtn:hover {
  background-color: #007bff;
  color: white;
}

/* Forgot Password */
.ForgotPassArticle {
  padding: 1rem; /* Reduced padding */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 1rem;
  background-color: #f4f4f4; /* Lighter background */
  max-width: 300px; /* Matched with the login form */
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.CheckEmail {
  background-color: #28a745; /* Success color */
  color: white;
  padding: 10px;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

/* Styling the input field inside ForgotPassArticle */
.ForgotPassArticle input[type="email"] {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.ForgotPassArticle input[type="email"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Reset password */
/* Container for the password change form */
.divChangePass {
  position: relative;
  background-color: #fff; /* white background */
  padding: 20px;
  width: 250px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
}

/* Inner div containing the form fields */
.divChangePassIn {
  display: flex;
  flex-direction: column;
  gap: 10px; /* spacing between form elements */
}

/* Labels for the inputs */
.divChangePassIn label {
  font-size: 16px;
  font-weight: bold;
}

/* For password input fields */
.divChangePassIn input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* For passwords that don't match */
.notSimilarPass {
  box-shadow: 0px 0px 8px 2px #d9534f;
}

/* Button styles */
.divChangePassIn button {
  padding: 10px 20px;
  background-color: #007bff; /* blue background */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* On hover */
.divChangePassIn button:hover {
  background-color: #0056b3; /* darker blue */
}

/* Back to login button */
.BackTiLogInBtn {
  background-color: #ccc; /* gray */
}

.BackTiLogInBtn:hover {
  background-color: #aaa; /* darker gray */
}

/* sign in */

/* New styles for success registration */
.successReg {
  background-color: #28a745; /* green for success */
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
}

.registerFormIn {
  padding: 2rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
}

.registerFormIn label {
  font-weight: bold;
  color: #333;
  margin-top: 5px;
  text-align: center;
}

.registerFormIn input {
  width: 100%;
  padding: 5px;
  margin: 1px 0;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

/* Additional styles for the Terms and Conditions and Privacy Policy */
.registerFormIn div {
  margin-top: 20px;
  font-size: small;
  text-align: center;
}

/* Style for links in the Terms and Conditions and Privacy Policy section */
.registerFormIn div a {
  color: #007bff; /* Blue to indicate clickable link */
  text-decoration: underline;
}

/* Override for success message button */
.successReg button {
  background-color: white;
  color: #28a745; /* green for success */
  border: 2px solid white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.successReg button:hover {
  background-color: #28a745; /* green for success */
  color: white;
}

/* Mobile responsiveness */
@media only screen and (max-width: 410px) {
  .loginFormIn {
    width: 250px;
    /* box-sizing: border-box; */
  }
  .registerFormIn {
    width: 250px;
  }
}
