.footerMain {
  height: 800px;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 800px;
  z-index: -1;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 800px;
}
.footerRight {
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.orderCollectDetails {
  font-size: 18px;
  line-height: 1.6;
  color: #ffffff;
  margin-top: 20px;
  width: 50%;
  margin-left: 5%;
  margin-top: 70px;
  text-align: start;
}

.orderButton {
  background-color: #3498db;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 5%;
}

.orderButton:hover {
  background-color: #84cbf7;
}
.footerImage-take-away {
  height: 250px;
  width: 400px;
  margin-left: 50%;
  border-top-left-radius: 2.5rem;
}

.footerDetails {
  text-align: center;
  font-family: "Arial", sans-serif;
  padding: 20px;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom right, #c9c5c5, #ffffff);
  display: flex;
  justify-content: center;
  align-items: center;
}
.emptyDiv {
  background: none;
  width: 600px;
  height: 600px;
}

.footerImage {
  /* 1170x640 */
  position: fixed;
  width: 700px;
  height: 630px;
  bottom: 0px;
  left: 0px;
  z-index: -5;
}

.tripStyleDark {
  height: 800px;
  width: 100%;
  background-image: url("../../../images/paper-dark.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 10px 10px 100px 1px black;
  border-top-left-radius: 2rem;
}

.endFooter {
  height: 50px;
}

@media (max-width: 1450px) {
  .footerImage-take-away {
    display: none;
  }
}

@media (max-width: 1100px) {
  .orderCollectDetails {
    width: 70%;
  }
  .emptyDiv {
    width: 700px;
    height: 400px;
  }
  .footerImage {
    height: 110%;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .emptyDiv {
    width: 100%;
    height: 200px;
  }
  .footerImage {
    width: 100%;
  }

  .footerLeft {
    height: 400px;
  }
  .footer {
    flex-direction: column;
    height: auto;
  }

  .footerDetails {
    display: none;
  }
  .footerMain {
    height: auto;
  }
  .tripStyleDark {
    height: 900px;
  }
  .endFooter {
    height: 350px;
  }
}
