@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.FoodMenu {
  position: absolute;
  top: 155px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  background-color: white;
  z-index: 10;
  border-radius: 1rem;
  background-image: url("../../../images/menu.png");
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  opacity: 0.93;
}

.ourMenu {
  font-size: 35px;
  margin: 0;
  margin-top: 10px;
  font-family: "Lobster", cursive;
}

.FoodMenuOpenHours {
  color: rgba(6, 12, 143, 0.9);
  font-size: 25px;
  margin: 0;
  margin-top: 20px;
  margin-bottom: -12px;
}
.FoodMenuHours {
  color: rgba(33, 33, 33, 0.9);
  font-weight: 700;
}

.lineTitleMenu {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}

.lineTitle {
  flex: 1;
  border: 1px solid rgba(6, 12, 143, 0.9);
}
.titleSection {
  font-size: 25px;
  color: rgba(6, 12, 143, 0.9);
  font-weight: 700;
  padding: 10px;
}

.foodNameDiv {
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 18px;
  color: rgba(217, 1, 1, 0.9);
  font-weight: 700;
  margin-top: 15px;
}
.foodIngredients {
  width: 70%;
  margin-left: 10%;
  text-align: start;
  color: rgba(0, 0, 0, 0.727);
}

.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.scroll-to-top__button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.scroll-to-top__button:hover {
  opacity: 1;
}

.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-left: 80%;
  position: relative;
}

.close-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-line {
  width: 34px;
  height: 3px;
  background-color: #ff0000;
  position: absolute;
  transition: transform 0.3s ease;
}

.close-line1 {
  transform: rotate(45deg);
}

.close-line2 {
  transform: rotate(-45deg);
}

.close-button:hover .close-line1,
.close-button:hover .close-line2 {
  transform: rotate(0deg);
}

@media (max-width: 750px) {
  .shopImage {
    width: 80%;
  }
  .foodNameDiv {
    width: 98%;
    margin-left: 1%;
  }
  .foodIngredients {
    width: 65%;
  }
}
