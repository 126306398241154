.Verify-page {
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.veriry-page-image {
  height: 200px;
  width: 200px;
}
